// @flow

import { observable, action, computed } from "mobx";
import React from "react";
import { observer, inject } from "mobx-react";
import { Provider } from "mobx-react";
// import { TweenLite } from 'gsap/all';
import * as classNames from "classnames";

import styles from "./soundsystem.module.scss";
import { STORE_JSON, STORE_UI } from "../constants/stores";
import JsonStore from "../stores/json-store";
import UiStore from "../stores/ui-store";
import Metadata from "../components/Metadata";
// import Metadata from '../components/Metadata';

type IndexProps = {
  json: JsonStore,
  ui: UiStore,
  data: any,
  location: any,
};

@inject(STORE_JSON, STORE_UI)
@observer
class IndexPage extends React.Component<IndexProps, any> {
  ref: any;
  stores: any;

  @observable loaded: boolean = false;

  @action
  setLoaded = (loaded: boolean): void => {
    this.loaded = loaded;
  };

  @observable locked: boolean = true;

  @action
  setLocked = (locked: boolean): void => {
    this.locked = locked;
  };

  @computed get isLoggedIn(): boolean {
    return this.value === "takemewithyou";
  }

  @observable value: string = "";

  @action
  setValue = (value: any): void => {
    if (value && value.target) {
      this.value = value.target.value;
    }
  };

  clicked = (): void => {
    if (this.isLoggedIn && this.locked) {
      this.setLocked(false);
      window.removeEventListener("keydown", this.keyListenBound);
    }
  };

  constructor(props: IndexProps, context: any) {
    super(props, context);
    this.props[STORE_UI].bgToggle("soundsystem");
    this.ref = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("keydown", this.keyListenBound);
    if (this.ref && this.ref.current) {
      this.ref.current.focus();
    }
  }

  pictureLoaded = () => {
    this.setLoaded(true);
  };

  keyListen(e: any) {
    if (e.keyCode === 13) {
      this.clicked();
    }
  }

  keyListenBound: any = this.keyListen.bind(this);

  render() {
    return (
      <Provider {...this.stores}>
        <>
          <div
            className={classNames({
              [styles.soundsystemWrapper]: true,
            })}
          >
            <img
              src="https://images.ctfassets.net/6xh2c92820gv/1wIPlcHJmqYOemiQ3E2DJA/122483c605da2d15b47f7e3893f3997d/pbslogo.svg"
              alt=""
            />
          </div>
          <Metadata
            data={{
              title: "Pure Beauty Soundsystem",
            }}
            location={this.props.location}
          />
          <div className={styles.video} />
          <div
            className={classNames({
              [styles.login]: true,
              [styles.locked]: this.locked,
            })}
          >
            <input
              type="password"
              autoComplete="new-password"
              onInput={this.setValue}
              ref={this.ref}
            />
            <a role="button" onClick={this.clicked}>
              ACCESS
            </a>
          </div>
          <div
            className={classNames({
              [styles.link]: true,
              [styles.active]: !this.locked,
            })}
          >
            <div className={styles.bg} />
            <a href="https://we.tl/t-o357zpu9zB" target={`_blank`}>
              DOWNLOAD EPISODE 09
            </a>
          </div>
        </>
      </Provider>
    );
  }
}

export default IndexPage;
